.section-details-modele-abonnement {
    width: 450px;
    padding: 1em;
    background: var(--bleu-clair);
    color: var(--noir);
}

.section-details-modele-abonnement .close-button-container {
    display: flex;
    justify-content: end;
}

.section-details-modele-abonnement button.close-button {
    color: var(--bleu-sur-clair-AA);
    padding: 12px;
}

.section-details-modele-abonnement h1 {
    font-family: "PoppinsSemiBoldItalic";
    color: var(--bleu-sur-clair-AA);
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 30px;
}

.section-details-modele-abonnement .chargement-donnees-container {
    text-align: center;
}

ul.liste-articles-modele-abonnement {
    padding-left: 20px;
}

ul.liste-articles-modele-abonnement li {
    font-family: "PoppinsRegular";
    font-size: 0.9rem;
    list-style: none;
    margin-bottom: 30px;
}

ul.liste-articles-modele-abonnement li span.nom-article {
    font-family: "PoppinsSemiBold";
    color: var(--bleu-sur-clair-AA);
    font-size: 1rem;
}

ul.liste-articles-modele-abonnement li span.souscris {
    background: var(--vert-sur-blanc-AA);
    color: var(--blanc);
    margin-left: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

ul.liste-articles-modele-abonnement > li a span.souscris {
    border-radius: 4px;
    padding: 2px 10px;
    font-family: "PoppinsSemiBold";
}

ul.liste-articles-modele-abonnement li div.description-article-container {
    margin-top: 5px;
}

ul.liste-articles-modele-abonnement li div.description-article-container p {
    font-style: italic;
    padding: 0;
    margin: 0;
    color: var(--noir);
}

ul.liste-articles-modele-abonnement ul.liste-prix-article {
    padding-left: 20px;
    margin-top: 20px;
}

ul.liste-articles-modele-abonnement ul.liste-prix-article li {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 10px;
}

ul.liste-articles-modele-abonnement ul.liste-prix-article li span.souscris {
    font-size: 0.7em;
    border-radius: 50%;
    padding: 4px;
    height: 1em;
    width: 1em;
}

ul.liste-articles-modele-abonnement ul.liste-prix-article li span.prix-libelle {
    display: flex;
}

ul.liste-articles-modele-abonnement ul.liste-prix-article li span.prix-article {
    font-family: "PoppinsSemiBold";
    justify-self: end;
}

.section-details-abonnes span.nombre-abonnes {
    display: block;
    text-align: center;
    border-bottom: 1px solid var(--bleu-moyen);
    padding-bottom: 20px;
    font-family: PoppinsSemiBold;

    & > span {
        font-family: PoppinsBold;
        font-size: 2em;
        margin-right: 8px;
    }
}

.section-details-abonnes ul {
    list-style: none;
    padding: 0;
    margin-block: 20px 0;
    color: var(--bleu-sur-clair-AA);

    & > li + li {
        margin-top: 20px;
    }

    & > li > details > ul {
        display: grid;
        grid-template-columns: auto auto 1fr;
        column-gap: 5px;
        margin-top: unset;
        margin-left: 3px;
        padding-left: 10px;
        padding-top: 10px;
        color: var(--noir);
        border-left: 1px solid var(--bleu-sur-clair-AA);

        & li + li {
            margin-top: 10px;
        }

        & li {
            display: grid;
            grid-template-columns: subgrid;
            grid-column: 1/-1;
            align-items: center;

            & a.code-abonnement {
                color: var(--noir);
                border: solid 1px currentColor;
                border-radius: 6px;
                padding: 1.5px 5px;
                font-family: "PoppinsSemiBold";
                font-size: 1rem;
                text-align: center;
            }

            & a:hover {
                color: var(--bleu-sur-clair-AA);
                text-decoration: underline;
            }
        }
    }
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
