aside {
    background-color: var(--noir);
    color: var(--blanc);
    padding: 1rem;
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    z-index: 100;
    display: none;
    flex-direction: column;
    position: fixed;
}

aside.visible {
    display: initial;
    max-height: 100%;
    /* Pour que le menu mobile scroll */
    overflow: auto;
}

aside a {
    color: var(--blanc);
    text-decoration: none;
    transition: 0.3s;
}

/* Quand le menu mobile est visible, on cache la scrollbar du main */
aside.visible + main {
    overflow: hidden;
}

@media screen and (min-width: 700px) {
    aside {
        display: initial;
        /*On passe en static pour l'aside reprenne sa place*/
        position: static;
    }

    aside.visible + main {
        overflow: unset;
    }
}

/*************
 HAUT DU MENU VERSION MOBILE
 *************/

#mobile-menu-top {
    justify-content: end;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.cross-btn {
    color: var(--bleu);
}

.cross-btn:hover {
    color: var(--blanc);
}

@media screen and (min-width: 700px) {
    #mobile-menu-top {
        display: none;
    }
}

/*************
 BOUTON COMPTE
 *************/

#account-info {
    background-color: var(--bleu);
    color: var(--noir);
    border-radius: 15px;
    display: grid;
    grid-template-areas: "icon username"
                         "icon lien-compte";
    gap: 0.2em 1em;
    align-items: center;
    padding: 20px 15px;
    max-width: 200px;
    margin: 0 auto var(--aside-margin) auto;
}

.logout {
    grid-area: icon;
    color: currentColor;
    font-size: 2rem;
}

.power-off-icon:hover {
    color: var(--blanc);
}

#account-info div a:hover {
    text-decoration: underline;
}

.username {
    grid-area: username;
    font-family: "PoppinsSemiBold";
    font-size: 1.1rem;
    word-break: break-word;
}

.my-account {
    grid-area: lien-compte;
    color: var(--noir);
    font-size: 0.9rem;
    font-family: "PoppinsMedium";
    text-decoration: underline;
}

/*************
 NOM COMMUNE
 *************/

#city-info {
    color: var(--bleu);
    font-family: "PoppinsSemiBold";
    text-align: center;
    word-break: break-word;
    margin: 2rem auto 1rem auto;
    font-size: 1.2rem;
}

hr {
    width: 50%;
    margin: auto;
    border-bottom: 1px solid var(--bleu);
}

/*************
 MENU LINKS
 *************/

#aside-menu-list {
    margin: 0;
    padding: 2rem 0;
}

#aside-menu-list li {
    list-style-type: none;
}

#aside-menu-list li + li {
    margin-top: 1.5rem;
}

.menu-item a {
    font-family: "PoppinsMedium";
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.menu-item a:is(:hover, :focus-visible) {
    transform: translate(5px);
}

.menu-item .active-menu-item {
    font-family: "PoppinsSemiBold";
}

.active-menu-item .rounded-icon {
    background-color: var(--bleu);
    color: var(--blanc);
}

@media screen and (min-width: 700px) {
    /* Sur grand écran, on ne veut pas centrer les liens */
    .menu-item a {
        justify-content: start;
    }
}


/* Icones */

/*Permet de modifier les propriétés de base de FontAwsome*/
aside {
    --fa-border-color: var(--bleu);
    --fa-border-radius: 50%;
    --fa-border-padding: 5px;
}

.rounded-icon {
    color: currentColor;
    border: 1px solid currentColor;
    border-radius: 50%;
    padding: 8px;
    aspect-ratio: 1 / 1;
}

/*************
 BOTTOM
 *************/
div.aside-bottom {
    margin-top: calc(var(--aside-margin)*5);
}

.legal-link:hover {
    color: var(--bleu);
}

.legal-link {
    display: block;
    text-decoration: underline;
    text-align: center;
    font-size: 0.9rem;
    margin: 0.5rem auto;
}

/*************
 VARIABLES
 *************/
aside {
    --aside-margin: 1rem;
}
