section.page-abonnement .page-top-section-header {
    max-width: var(--width-table);
    margin-inline: auto;
    border-radius: 15px;
}

section.page-abonnement-admin .page-top-section-header {
    background-color: var(--violet-clair);
}

.page-abonnement-admin .nom-collectivite {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-inline: 15px;
    padding-top: 60px;
    text-align: center;
    word-break: break-word;
    font-family: "PoppinsSemiBoldItalic";
    font-size: 1.7em;
}

.page-abonnement h1.titre-page {
    flex-wrap: wrap;
    /* Pour que le bouton retour soit toujours affiché à gauche, meme quand les éléments du h1 wrap */
    justify-content: flex-start;
    row-gap: 30px;
    /* On override le padding de base de la h1 pour qu'il y ait moins d'espace avec le bouton statut */
    margin-bottom: 20px;
}

.page-abonnement-admin h1.titre-page {
    margin-block: 0 20px;
    padding: 15px 15px 60px 15px;
}

.page-abonnement .modele-abonnement {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px 15px;
    text-align: center;
}

.page-abonnement div.modele-abonnement div:has(.code-abonnement) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
    justify-content: center;
}

.page-abonnement .code-abonnement {
    border: solid 1px var(--noir);
    border-radius: 6px;
    padding: 1.5px 5px;
    font-family: "PoppinsSemiBold";
    font-size: 1rem;
}

.page-abonnement .statut-abonnement {
    display: flex;
    gap: 5px;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    color: var(--blanc);
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 40px;
    font-family: "PoppinsSemiBold";
}

.page-abonnement .statut-abonnement[data-statut-abonnement="En cours"]{
    background-color: var(--vert-sur-blanc-AA);
}

.page-abonnement .statut-abonnement[data-statut-abonnement="Terminé"]{
    background-color: var(--gris-sur-blanc-AA);
}

/* Frise dates */
.page-abonnement .timeline-container {
    --color-svg: var(--gris-sur-blanc-AA);
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: auto auto auto;
    justify-items: center;
    align-items: end;
    gap: 10px;
    max-width: 800px;
    margin-block: 60px;
    margin-inline: auto;
    padding: 1.8em 1em;
    border-radius: 10px;
    background: var(--bleu-clair);
}

.page-abonnement .timeline-container[data-fin] {
    grid-template-columns: repeat(5, auto);
}

.page-abonnement .timeline-container dt {
    grid-row: 1 / 2;
    font-family: "PoppinsSemiBold";
    text-align: center;
}

.page-abonnement .timeline-container dd {
    /* On enlève la marge par defaut ajouté par le navigateur */
    margin-inline: 0;
    grid-row: 3 / 4;
    font-family: "PoppinsRegular";
}

.page-abonnement .timeline-container .timeline-section-debut {
    grid-column: 1 / 3;
}

.page-abonnement .timeline-container  .timeline-section-reflexion {
    grid-column: 3 / 4;
}

.page-abonnement .timeline-container  .timeline-section-limite-preavis {
    grid-column: 4 / 5;
}

.page-abonnement .timeline-container  .timeline-section-tacite-reconduction {
    grid-column: 5 / 6;
}

.page-abonnement .timeline-container .timeline-section-fin {
    grid-column: 6 / 8;
}

.page-abonnement .timeline-container[data-fin] .timeline-section-fin {
    grid-column: 4 / 6;
}

.page-abonnement .timeline-container svg {
    height: 12px;
    grid-row: 2 / 3;
}

.page-abonnement .timeline-container svg.line.horizontal {
    grid-column: 2 / 7;
    grid-row: 2 / 3;
    justify-self: stretch;
}

.page-abonnement .timeline-container[data-fin] svg.line.horizontal{
    grid-column: 2 / 5;
}

.page-abonnement .timeline-container svg circle {
    fill: var(--color-svg);
}

.page-abonnement .timeline-container svg line {
    stroke: var(--color-svg);
}

.page-abonnement .timeline-container svg.line.vertical {
    display: none;
}

.page-abonnement .timeline-container time.date-fin:empty::after {
    content: "—";
    color: var(--noir);
}

/* Table */

.page-abonnement table tfoot tr {
    background: unset;
    box-shadow: unset;
}

.page-abonnement table tfoot td.abonnement-total-annuel {
    font-family: "PoppinsSemiBold";
    text-align: end;
    border-radius: unset;
    background: unset;
    /* On ne reset que le padding vertical pour que le texte soit aligné avec la dernière colonne */
    padding-block: unset;
}

@container contenu (width < 800px) {
    table.details-abonnement {
        word-break: break-word;
    }

    table.details-abonnement thead {
        display: none;
    }

    table.details-abonnement tr {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px 10px;
        padding: 1em;
        background: var(--blanc);
    }

    table.details-abonnement td {
        padding: 0.5em 0.5em;
    }

    table.details-abonnement td.info-article {
        font-family: "PoppinsSemiBold";
        justify-self: center;
    }

    table.details-abonnement .small-label {
        display: block;
        font-family: "PoppinsSemiBold";
        padding-bottom: 10px;
    }

    .page-abonnement table tfoot td.abonnement-total-annuel {
        text-align: center;
    }
}

@container contenu (width < 600px) {
    .page-abonnement .timeline-container {
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(10, auto);
        align-items: center;
        justify-items: start;
        column-gap: 20px;
        row-gap: 5px;
    }

    .page-abonnement .timeline-container[data-fin] {
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(6, auto);
    }

    .page-abonnement .timeline-container svg {
        grid-column: 1 / 2 !important;
    }

    .page-abonnement .timeline-container :is(dt, dd) {
        grid-column: 2 / 3 !important;
    }

    .page-abonnement .timeline-container dt {
        text-align: unset;
    }

    .page-abonnement .timeline-container dt.timeline-section-debut {
        grid-row: 1 / 2;
    }

    .page-abonnement .timeline-container dd.timeline-section-debut {
        grid-row: 2 / 3;
    }

    .page-abonnement .timeline-container .point.timeline-section-debut {
        grid-row: 1 / 3;
    }

    .page-abonnement .timeline-container dt.timeline-section-reflexion {
        grid-row: 3 / 4;
        padding-top: 20px;
    }

    .page-abonnement .timeline-container dd.timeline-section-reflexion {
        grid-row: 4 / 5;
        padding-bottom: 20px;
    }

    .page-abonnement .timeline-container .point.timeline-section-reflexion {
        grid-row: 3 / 5;
    }

    .page-abonnement .timeline-container dt.timeline-section-limite-preavis {
        grid-row: 5 / 6;
        padding-top: 20px;
    }

    .page-abonnement .timeline-container dd.timeline-section-limite-preavis {
        grid-row: 6 / 7;
        padding-bottom: 20px;
    }

    .page-abonnement .timeline-container .point.timeline-section-limite-preavis {
        grid-row: 5 / 7;
    }

    .page-abonnement .timeline-container dt.timeline-section-tacite-reconduction {
        grid-row: 7 / 8;
        padding-top: 20px;
    }

    .page-abonnement .timeline-container dd.timeline-section-tacite-reconduction {
        grid-row: 8 / 9;
        padding-bottom: 20px;
    }

    .page-abonnement .timeline-container .point.timeline-section-tacite-reconduction {
        grid-row: 7 / 9;
    }

    .page-abonnement .timeline-container dt.timeline-section-fin {
        grid-row: 9 / 10;
    }

    .page-abonnement .timeline-container[data-fin] dt.timeline-section-fin {
        grid-row: 5 / 6;
    }

    .page-abonnement .timeline-container dd.timeline-section-fin {
        grid-row: 10 / 11;
    }

    .page-abonnement .timeline-container[data-fin] dd.timeline-section-fin {
        grid-row: 6 / 7;
    }

    .page-abonnement .timeline-container .point.timeline-section-fin {
        grid-row: 9 / 11;
    }

    .page-abonnement .timeline-container[data-fin] .point.timeline-section-fin {
        grid-row: 5 / 7;
    }

    .page-abonnement .timeline-container svg.line.vertical {
        grid-row: 2 / -2;
        display: unset;
        height: unset;
        width: 12px;
        align-self: stretch;
    }

    .page-abonnement .timeline-container svg.line.horizontal {
        display: none;
    }
}

@container contenu (width < 250px) {
    .page-abonnement .timeline-container {
        display: block;
        word-break: break-all;
    }

    .page-abonnement .timeline-container svg {
        display: none !important;
    }
}


/* Liste des documents */

ul.liste-documents {
    /* liste alignée sur la table des articles au dessus */
    width: var(--width-table);
    margin: 0 auto;
    list-style-type: none;
    padding: 0;

    word-break: break-word;
}

ul.liste-documents li {
    display: flex;
    align-items: start;
}

ul.liste-documents li + li {
    margin-top: 10px;
}


ul.liste-documents button.fichier-download {
    padding-right: 0.5em;
}

ul.liste-documents button.fichier-show {
    text-align: start;
    user-select: text;
}

ul.liste-documents button.fichier-download:hover,
ul.liste-documents button.fichier-show:hover {
    text-decoration: underline;
    color: var(--bleu-sur-clair-AA);
}

ul.liste-documents span.spinner-loader {
    /* même taille que l'icone download (fa-lg = 1.25em) */
    width: 1.25em;
    margin-right: 1.25em;
}
