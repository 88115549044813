section.page-ticket {
    --section-max-width: 650px;
}

section.page-ticket .page-top-section-header {
    max-width: var(--section-max-width);
    margin-inline: auto;
    border-radius: 15px;
}

section.page-ticket-admin .page-top-section-header {
    background-color: var(--jaune);
}

.page-ticket-admin .nom-collectivite {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-top: 60px;
    padding-inline: 15px;
    text-align: center;
    word-break: break-word;
    font-family: "PoppinsSemiBoldItalic";
    font-size: 1.7em;
}

.page-ticket-admin h1.titre-page {
    padding: 15px 15px 60px 15px;
    margin-top: 0;
}

.page-ticket h1 > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.section-details-ticket {
    max-width: var(--section-max-width);
    margin-inline: auto;
    color: var(--noir);
    word-break: break-word;
}

.section-identification-ticket h2 {
    font-size: 22px;
}

.section-identification-ticket .infos-ticket {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    justify-content: space-between;
}

.section-identification-ticket .infos-ticket .statut-ticket {
    margin-bottom: 10px;
    background: var(--blanc);
    padding: 0px 5px;
    border-radius: 4px;
    border: 1px solid currentColor;
    width: max-content;
}

.section-identification-ticket .dates-ticket{
    font-family: "PoppinsLightItalic";
    text-align: end;
    margin-left: auto;
}

.section-identification-ticket .label-description {
    margin-top: 10px;
}

div.description-ticket {
    min-width: 0;
    margin-top: 10px;
    padding-inline: 1em;
    border-left: 2px solid var(--gris-sur-blanc-AA);
    /* On veut afficher les espaces et sauts de lignes renseignés par l'utilisateur lors de la création */
    white-space: pre-wrap;
}

/* La description est wrappé dans un <p>, et on veut annuler la margin-top de celui ci */
div.description-ticket > p:first-of-type {
    margin-top: 0;
  }

div.description-ticket h1.titre-donnees-formulaire,
div.description-ticket h2.sous-titre-donnees-formulaire {
    color: var(--gris-sur-blanc-AA);
    font-family: "PoppinsMedium";
    margin-bottom: 0.5rem;
    text-align: start;

}

div.description-ticket h1.titre-donnees-formulaire {
    font-size: 1.5rem;
}

div.description-ticket h2.sous-titre-donnees-formulaire {
    font-size: 1.25rem;
}

div.description-ticket div p {
    margin-top: 0;
}

div.description-ticket img {
    max-width: 100%;
}

.section-details-ticket hr {
    margin-block: 30px;
    width: auto;
}

ul.liste-commentaires {
    padding: 0px;
}

ul.liste-commentaires > li {
    list-style: none;
    margin-bottom: 30px;
    padding: 1.5em 1em;
    background: var(--blanc);
    border-radius: 10px;
    margin-right: 50px;
    border: solid 2px var(--bleu-clair);
}

/* bordure colorée selon le statut des solutions,
   hex identiques à l'interface web de glpi */
ul.liste-commentaires > li:not(.commentaire-tache, .commentaire-tache-prive) {
    &[data-status="2"] {
        border-color: #dd9d27;
    }

    &[data-status="3"] {
        border-color: var(--vert-sur-blanc-AA);
    }

    &[data-status="4"] {
        border-color: #e25050;
    }
}

ul.liste-commentaires .commentaire-from-user-collectivite {
    margin-left: 50px;
    margin-right: 0px;
    border: solid 2px var(--bleu-sur-clair-AA);
}

ul.liste-commentaires .commentaire-prive {
    background: var(--gris-clair);
    border-color: var(--gris-clair);
}

ul.liste-commentaires .commentaire-solution {
    background: var(--bleu-clair);
}

ul.liste-commentaires .commentaire-tache {
    --background-color-ticket-task:#ffe8b9;
    background-color: var(--background-color-ticket-task);
    border-color: var(--background-color-ticket-task);
}

ul.liste-commentaires li :is(.icone-commentaire, .icone-task) {
    margin-right: 10px;
    color: var(--bleu-sur-clair-AA);
}

ul.liste-commentaires li:has(.task-status)  .icone-task {
    margin-right: 5px;
}

ul.liste-commentaires li .task-status {

    color: var(--blanc);
    border-radius: 4px;
    padding-inline: 5px;
    margin-right: 10px;

    li[data-status="1"] & {
        background: var(--bleu-sur-clair-AA);
    }

    li[data-status="2"] & {
        background: var(--vert-sur-blanc-AA);
    }
}

ul.liste-commentaires li span.user-commentaire {
    color: var(--bleu-sur-clair-AA);
    font-family: "PoppinsSemiBold";
}

ul.liste-commentaires li .icone-private {
    display: flex;
    margin-left: auto;
}

ul.liste-commentaires li .details-task {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    font-family: "PoppinsLight";
    --color-details-ticket-task:#f76707;
    border-block: 1px solid var(--color-details-ticket-task);
    padding: 0.7em;
    margin-block: 5px 30px;
}

ul.liste-commentaires li .details-task .icone-details-task {
    margin-right: 10px;
    color: var(--color-details-ticket-task);
    width: 18px;
    height: 18px;
}

ul.liste-commentaires li .contenu-commentaire {
    max-width: 100%;
    min-width: 0;
    /* On veut afficher les espaces et sauts de lignes renseignés par l'utilisateur lors de l'ajoutd 'un commentaire' */
    white-space: pre-wrap;
}

ul.liste-commentaires li .contenu-commentaire img {
    max-width: 100%;
}

ul.liste-commentaires li p {
    margin: 0;
}

ul.liste-commentaires li p + p {
    margin-top: 5px;
}

ul.liste-commentaires li .date-commentaire,
ul.liste-commentaires li .solution-status {
    display: flex;
    justify-content: end;
    font-size: 0.9em;
    font-family: "PoppinsLightItalic";
    margin-top: 0px;
    text-align: end;
}

ul.liste-commentaires li :is(.contenu-commentaire, .date-commentaire) {
    margin-top:10px;
}

.form-ajout-commentaire label {
    font-family: "PoppinsSemiBold";
}

.form-ajout-commentaire .input-commentaire-ticket {
    margin-block: 15px;
    padding: 1.5em 1em;
    background: var(--blanc);
    border-radius: 10px;
    border: solid 2px var(--bleu-clair);
    min-width: 100%;
    box-sizing: border-box;
}

.form-ajout-commentaire .boutons-action {
    display: flex;
    gap:10px;
    justify-content: end;
    flex-wrap: wrap;
}

.form-ajout-commentaire .boutons-action .bouton-validation,
.form-ajout-commentaire .boutons-action .bouton-refus {
    color: var(--blanc);
    font-weight: 600;
    padding: 15px 20px;
    border-radius: var(--btn-border-radius);
}

.form-ajout-commentaire .boutons-action .bouton-validation {
    background-color: var(--vert-sur-blanc-AA);
}
.form-ajout-commentaire .boutons-action .bouton-refus {
    background-color: var(--rouge-vif);
}
