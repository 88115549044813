.section-details-stats {
    width: 550px;
    padding: 1em;
    box-sizing: border-box;
}

.bloc-bleu {
    background-color: var(--bleu-moyen);
    color: var(--bleu-fonce);
}

.bloc-vert {
    background-color: var(--vert-clair);
    color: var(--vert-fonce);
}

.bloc-rouge {
    background-color: var(--rouge-clair);
    color: var(--rouge-fonce);
}

.bloc-jaune {
    background-color: var(--jaune);
    color: var(--marron);
}

.bloc-jaune-clair {
    background-color:var(--jaune-clair);
    color: var(--marron-clair);
}

.bloc-bleu-clair {
    background-color: var(--bleu-clair);
    color: var(--bleu-sur-bleu-clair-AA);
}

.section-details-stats .close-button-container {
    display: flex;
    justify-content: end;
}

.section-details-stats button.close-button {
    padding: 12px;
}

.section-details-stats h1 {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 30px;
    padding-inline: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(100,100,100,0.4);
    text-wrap: balance;
}

/* On ne veut pas afficher la bordure pour les volets avec graphes */
.section-details-stats:has(canvas) h1 {
  border-bottom: none;
}

.section-details-stats canvas{
  margin-top: 80px;
}

.section-details-stats ul li{
    list-style: none;
}

.section-details-stats ul li + li{
    margin-top: 10px;
}