input:focus,
textarea:focus,
select:focus {
    outline: none;
}

:root {
    --border-radius-input: 15px;
}

/*****************
INPUTS NORMAUX
****************/

.normal-input {
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--bleu-clair);
    border: 1px solid var(--bleu-clair);
    border-radius: var(--border-radius-input);
}

.display-only-input {
    box-sizing: border-box;
    padding: 10px 0;
    background-color: transparent;
    border: 1px solid transparent;
}

/****************
INPUTS WITH ICON
****************/

.input-with-icon:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px 1px;
}
.input-with-icon-container:focus-within {
    outline: 2px solid var(--gris-sur-blanc-AA);
    border-radius: var(--border-radius-input);
}

.input-with-icon {
    height: 37px;
    width: 80%;
    max-width: 350px;
    border: 1px solid var(--bleu-clair);
    box-sizing: border-box;
    border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0;
    padding-inline: 5px;
    flex: 1;
}

.input-with-icon-container {
    display: flex;
    height: 37px;
    width: 80%;
    max-width: 350px;
    margin: 1rem auto;
}

.input-with-icon-container span {
    padding-left: 10px;
    background-color: var(--bleu-clair);
    height: 72%;
    padding-top: 10px;
    text-align: center;
    border-radius: 15px 0 0 15px;
    width: 30px;
    color: var(--noir);
}
