:root {
  --width-table: min(1200px, 100%);
}

body {
    margin: 0;
    color: var(--noir);
    font-family: "PoppinsRegular", "sans-serif";
}

/******************************
 Layout de base des pages
 ******************************/

#root {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
  "sidebar header"
  "sidebar contenu";
  min-height: 100vh;
  box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    #root {
        grid-template-columns: minmax(100px, 250px) 1fr;
    }
}

#root > header {
  grid-area: header;
}

#root > aside {
  grid-area: sidebar;
}

#root > main {
  grid-area: contenu;
  container-type: inline-size;
  container-name: contenu;
  background-color: var(--blanc-grise);
}

main > .chargement-route-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}

.spinner-loader {
  aspect-ratio: 1;
  width: 40px;
  animation: spinner 0.8s ease infinite;
  display: inline-block;
  background: url("../public/img/logo_N_bleu.svg") no-repeat;
  background-size: contain;
  background-position: center center;
}

.display-logged-out {
  width: 100vw;
  min-height: 100vh;
  container-type: inline-size;
  container-name: contenu;
}

.display-logged-out > header {
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: 0 3px 8px rgba(0,0,0,.24);
}

.display-logged-out > header > img {
  max-width: 450px;
  padding: 15px 15px 0 15px;
}

.section-contenu-page {
  padding: 1.5em;
}

/***************************
 Style des éléments globaux
 ***************************/

h1.titre-page {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: "PoppinsSemiBoldItalic";
  margin-bottom: 60px;
  font-size: 26px;
  color: var(--noir);
  word-break: break-word;
}

h1.titre-avec-h2 {
  margin-bottom: 0;
}

h1 .icone-titre-page {
  border: 1px solid var(--color);
  border-radius: 50px;
  padding: 10px;
  color: var(--blanc);
  background: var(--color);
  --color: var(--bleu);
  aspect-ratio: 1 / 1;
}

h2 {
  width: 100%;
  font-family: "PoppinsMedium";
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--gris-sur-blanc-AA);
  word-break: break-word;
}

a {
    text-decoration: none;
}

a:hover {
    transition: 0.4s;
}

abbr.mention-hors-taxes {
  font-size: 0.9em;
}

:is(a, button):focus-visible {
  outline: 3px solid darkorange;
  outline-offset: 2px
}

:is(.error-msg, .success-msg) {
  font-size: 0.8rem;
  font-family: "PoppinsMediumItalic";
  border-radius: 5px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.error-msg {
  color: var(--rouge-fonce);
  background-color: var(--rouge-clair);
  margin: 1rem auto 2rem;
}

.success-msg {
  color: var(--blanc);
  background-color: var(--vert-sur-blanc-AA);
  margin: 1rem auto 2rem;
}


/*********************************
 Style des status des abonnements
 *********************************/

[data-statut-abonnement="En cours"] {
    color: var(--vert-sur-blanc-AA);
}

[data-statut-abonnement="Terminé"] {
    color: var(--gris-sur-blanc-AA);
}

/************************************************
 Layout de base pour les tables de l'application
 ************************************************/
table.table-buc {
  width: var(--width-table);
  border-spacing: 0 30px;
  margin: 0 auto;
}

table.table-buc thead {
  position: sticky;
  top:0;
  text-align: left;
}

table.table-buc tr {
  --border-radius-row: 10px;
  box-shadow: 0 5px 10px 0px #0000002e;
  border-radius: var(--border-radius-row);
}

table.table-buc tr + tr{
  margin-top: 30px;
}

table.table-buc th {
  padding: 1em 1em;
  background: var(--blanc);
  font-family: "PoppinsSemiBold";
}

table.table-buc td {
  padding: 1.5em 1em;
  background: var(--blanc);
}

table.table-buc :is(thead,tr) :is(th,td):first-of-type {
  border-radius: var(--border-radius-row) 0 0 var(--border-radius-row);
}

table.table-buc :is(thead,tr) :is(th,td):last-of-type {
  border-radius: 0 var(--border-radius-row) var(--border-radius-row) 0;
}

table.table-buc .small-label {
  display: none;
}

/***************************************************************
 Layout de base pour page statique (CGV, mentions legales, ...)
 ***************************************************************/
section.page-statique {
  max-width: 900px;
  margin-inline: auto;
}

section.page-statique .texte-gras {
  font-family: "PoppinsBold";
}

section.page-statique .texte-gris {
  color: var(--gris-sur-blanc-AA);
}

section.page-statique section.section-article {
  margin-top: 40px;
}

h2.sous-titre-page-statique {
  font-family: "PoppinsSemiBold";
  font-size: 22px;
}

section.page-statique section.section-article div.container-adresse :is(p, address) {
  display: inline;
}

section.page-statique section.sous-section {
  margin-top: 20px;
}

section.page-statique .sous-section h3 {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--gris-sur-blanc-AA);
  word-break: break-word;
}

section.page-statique address {
  font-style: normal;
}

section.page-statique :is(address, p, li){
  margin-bottom: 0px;
  line-height: 1.4;
}

section.page-statique a {
  color: var(--bleu-sur-clair-AA);
}

section.page-statique section.section-contact-cgv {
  margin-block: 80px 50px;
  text-align: center;
}
