header {
    background-color: var(--blanc-grise);
    height: 70px;
    /*Permet d'afficher le box shadow*/
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0.5rem 1rem;
    /*Permet de prendre tout l'espace y compris celui-de la side bar quand elle
    ne s'affiche pas*/
    width: calc(100vw - 2rem);
}

@media screen and (min-width: 500px) {
    header {
        padding: 0.5rem 1.5rem;
        width: calc(100vw - 3rem);
    }
}

@media screen and (min-width: 700px) {
    /*Permet d'afficher la sidebar correctement*/
    header {
        width: auto;
    }
}

/*****
LOGO
****/

#header-logo img {
    width: 32px;
}

@media screen and (min-width: 500px) {
    #header-logo img {
        width: 40px;
    }
}

@media screen and (min-width: 600px) {
    #header-logo img {
        width: 50px;
    }
}

/******
TEXTES
******/

/*Permet à header text de prendre toute la largeur
entre les deux autres containers*/
#header-text {
    flex-grow: 1;
    text-align: left;
}

.header-title {
    display: block;
    margin: 0;
    color: var(--bleu);
    font-size: 1.1rem;
    font-family: "PoppinsBold";
    font-weight: normal;
}

.header-subtitle {
    display: none;
}

@media screen and (min-width: 500px) {

    #header-text {
        padding-left: 1rem;
    }

    .header-title {
        font-size: 1.2rem;
    }

    .header-subtitle {
        display: block;
        margin: 0;
        font-size: 0.8rem;
        font-style: italic;
        font-weight: normal;
        color: var(--gris-sur-blanc-AA);
    }
}

@media screen and (min-width: 600px) {

    .header-subtitle {
        font-size: 0.9rem;
    }
}

/*************
MENU HAMBURGER
**************/

#hamburger-icon {
    color: var(--gris-sur-blanc-AA);
}

@media screen and (min-width: 700px) {
    #hamburger-icon-wrapper {
        display: none;
    }
}
