div.modeles-abonnement-container {
    --padding-page: 80px;
    display: flex;
    justify-content: center;
    height: 100%;
}

div.modeles-abonnement-container .section-contenu-page {
    flex: 1;
}

/*
    Fix accessibilité temporaire pour le filtre de la page 'tous nos services'
    A supprimer après la résolution de l'issue http://gitlab.numerian.lan/dev/buc/issues/201
*/
:is(input, summary):focus-visible {
    outline: 3px solid darkorange !important;
    outline-offset: 2px
}

details:has(.filtre-liste-categories) {
    padding-inline: var(--padding-page);
    margin-bottom: 20px;

    & summary {
        background: var(--noir);
        color: white;
        padding: 5px 15px;
        border-radius: 15px;
        max-width: fit-content;
        list-style: none;
    }

    & form {
        border-left: 2px solid var(--noir);
        padding: 10px;
    }

    & .checkbox-filter {
        display: flex;
        gap: 10px 20px;
        flex-wrap: wrap;

        &  label {
            padding-inline: 3px;
            border-block-end: 2px solid var(--couleur-categorie);
            accent-color: var(--couleur-categorie);

            & input {
                margin-left: 0px;
            }
        }
    }

    & div.filtre {
        border-block-end: 2px solid var(--vert-sur-blanc-AA);
        accent-color: var(--vert-sur-blanc-AA);
        max-width: fit-content;
        margin-top: 20px;
    }
}

details[open=""] summary {
    border-radius: 15px 15px 15px 0;
}

ul.liste-modeles-abonnement {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 40px 40px;
    max-width: 100%;
    padding: 0 var(--padding-page) var(--padding-page) var(--padding-page);
    margin:0;
    align-items: stretch;
}

ul.liste-modeles-abonnement li {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5em;
    background: var(--couleur-modele, var(--bleu-clair));
    color: var(--noir);
    border: 4px solid transparent;
    border-radius: 20px;
    font-family: "PoppinsSemiBold";
    padding: 10px;
    font-size: 0.9em;
    list-style: none;
    min-height: 140px;
    container-type: inline-size;
}

ul.liste-modeles-abonnement li.categorie {
    --mix-color-categorie-dark: var(--noir) 60%;
    background-color: transparent;
    font-variant: all-small-caps;
    align-self: center;
    min-height: auto;
    border-radius: 0;
    border-width: 0 0 5px 0;
    font-size: 1.1em;
    grid-column: 1/-1;
}

ul.liste-modeles-abonnement li.selected {
    border-color: var(--bleu);
    box-shadow: 0 5px 10px 0px #0000002e;
}

ul.liste-modeles-abonnement li span.souscris {
    display: inline-block;
    background: var(--vert-sur-blanc-AA);
    color: var(--blanc);
    border-radius: 4px;
    padding: 2px 10px;
}

ul.liste-modeles-abonnement li footer:has(.abonnes) {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-top: auto;

    & button {
        border-width: 0;
        flex-grow: 1;
        line-height: 1.2;
        padding-block: 8px;
    }

    & button:first-of-type {
        background-color: var(--couleur-modele);
        filter: brightness(120%);
        border-radius: 10px 0 0 10px;
        border: 1px solid currentColor;
        color: var(--noir);

        &:hover {
            background-color: var(--noir);
            border-color: var(--noir);
            color: var(--bleu);
        }
    }

    & button:last-of-type {
        border-radius: 0 10px 10px 0;
    }

    @container (width < 250px ) {
        flex-direction: column;
        border-radius: 10px;
        padding-inline: 5px;

        & button:first-of-type {
            border-radius: 10px 10px 0 0;
        }
        & button:last-of-type {
            border-radius: 0 0 10px 10px;
        }
    }
}

ul.liste-modeles-abonnement li .bouton-detail-modele-abonnement {
    color: var(--blanc);
    border: 1px solid var(--noir);
    background: var(--noir);
    font-family: "PoppinsRegular";
    font-size: 0.8em;
    padding-inline: 10px;
    border-radius: 15px;
}

ul.liste-modeles-abonnement li .bouton-detail-modele-abonnement:hover {
    color: var(--bleu);
}

@container contenu (width < 1000px) {
     .modeles-abonnement-container.volet-detail-modele-visible .liste-modeles-abonnement {
        padding-inline: 10px;
    }
}

@container contenu (width < 700px) {
    /*
     * En dessous d'une certaine taille, on cache la liste des modeles lorsque le volet est
     *  visible pour qu'il prenne toute la place.
     */
     .modeles-abonnement-container.volet-detail-modele-visible .section-contenu-page {
        display: none;
    }

    .modeles-abonnement-container.volet-detail-modele-visible .section-details-modele-abonnement {
        /* On ajuste la taille pour que le volet ne colle pas à la barre latérale (car ils ont la meme couleur de fond) */
        width: 90%;
    }

    ul.liste-modeles-abonnement {
        grid-template-columns: 1fr;
    }

    ul.liste-modeles-abonnement li {
        min-height: 100px;
    }
}

@container contenu (width < 400px) {
    div.modeles-abonnement-container {
        --padding-page: 30px;
    }
}

@media (width < 700px) {
    /* Lorsque la barre latérale est cachée, on affiche le volet sur toute la largeur de l'écran */
    .modeles-abonnement-container.volet-detail-modele-visible .section-details-modele-abonnement{
        width: 100%;
    }
}
