/***************
    TITRE
***************/
.statut-adherent {
    display: flex;
    gap: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    flex-wrap: wrap;
    color: var(--noir);
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 40px;
    font-family: "PoppinsSemiBold";
    text-align: center;
    justify-content: center;
}

.statut-adherent[data-statut-adherent="adherent"] {
    background-color: var(--vert-sur-blanc-AA);
    color: var(--blanc);
}

.statut-adherent:not([data-statut-adherent="adherent"]) {
    background-color: var(--gris-sur-blanc-AA);
        color: var(--blanc);
}

/***************
   FORMULAIRE
***************/
.error-msg-collectivite {
    width: fit-content;
}

form.formulaire-collectivite {
    display:grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    max-width: 430px;
    background-color: var(--bleu-clair);
    border-radius: 15px;
    margin-inline: auto;
    padding: 1.5em;
    box-sizing: border-box;
}

form.formulaire-collectivite .item-container {
    display: flex;
    gap: 20px;
    min-width: 0;
    padding: 1rem;
    border-radius: 15px;
    background-color: var(--blanc-grise);
    box-shadow: 0 5px 10px 0px #0000002e;
}

.block-icon {
    color: var(--bleu);
    height: 30px;
    aspect-ratio: 1/1;
}

form.formulaire-collectivite .input-container {
    /* necessaire d'avoir les deux propriétés */
    max-width: 100%;
    min-width: 0;
}

form.formulaire-collectivite .input-container label {
    display: block;
    margin-bottom: 10px;
    font-family: "PoppinsSemiBold";
    word-break: break-word;
}

form.formulaire-collectivite .input-container .error-msg {
    margin-block: 0 15px;
    word-break: break-word;
}

form.formulaire-collectivite .input-container .normal-input {
    max-width: 100%;
}

form.formulaire-collectivite .display-only-input {
    color : var(--gris-sur-blanc-AA);
    max-width: 100%;
}

form.formulaire-collectivite input {
    width: 350px;
}

form.formulaire-collectivite .input-adresse-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    margin-top: 10px;
}

form.formulaire-collectivite .input-container .input-cp {
    max-width: 80px;
}

form.formulaire-collectivite .input-population-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 0;
}

form.formulaire-collectivite .bouton-action {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    max-width: fit-content;
    justify-self: center;
    justify-content: center;
}

@media screen and (max-width: 350px) {
    form.formulaire-collectivite {
        padding: 0.7em;
    }

    form.formulaire-collectivite .item-container {
        flex-direction: column;
    }
}

@media screen and (min-width: 1000px) {

    form.formulaire-collectivite {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, auto);
        grid-row-gap: 30px;
        grid-column-gap: 3em;
        max-width: 1000px;
        padding: 2.5em;
    }

    form.formulaire-collectivite input[name="collectivite[commune]"] {
        width: 235px;
    }

    form.formulaire-collectivite .bouton-action {
        grid-row: 4/5;
        grid-column: -3/-1;
        justify-self: center;
    }
}
