#main-connection {
    display: flex;
    width: 100vw;
    min-height: 100vh;
}

#connection-left {
    background-color: var(--blanc);
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
}

#connection-right {
    display: none;
    background-color: var(--blanc);
}

.input-element {
    margin-bottom: 2rem;
}

.input-element:last-of-type {
    margin-bottom: 4rem;
}

.connection-logo {
    max-width: 450px;
}

.error-msg-hidden {
    /* on veut garder l'espace même s'il n'y a pas d'erreur à afficher */
    visibility: hidden;
}

.connection-form {
    margin-bottom: 1.5rem;
    width: 100%;
}

/* pour qu'un lien imitant un submit button en dehors d'un formulaire ait la
  * même margin-bottom qye .connection-form, pour être visuellement cohérent
  */
a.submit-btn {
    margin-bottom: 1.5rem;
}

.mdp-oublie-link,
.inscription-link {
    color:var(--noir);
    font-size: 0.9rem;
    text-decoration: underline;
    margin-top: 3px;
}

div.lien-rgpd-container,
div.lien-services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 10px;
    color: var(--noir);
    font-size: 0.9rem;
}

div.lien-rgpd-container a {
    color: var(--noir);
    text-decoration: underline;
}

.mdp-oublie-link:hover,
.inscription-link:hover,
div.lien-rgpd-container a:hover {
    color:var(--bleu-sur-clair-AA);
}

p.recover-question,
p.confirm {
    font-family: "PoppinsSemiBold";
    font-size: 1.2em;
    margin-top:60px;
}

p.instructions-mdp {
    font-size: 0.9rem;
}

p.instruction-inscription {
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 1.5em;
    text-align: center;
    word-break: break-word;
    box-shadow: 0 5px 10px 0px #0000002e;
    background-color: var(--bleu-clair);
}

div.chargement-donnees-container {
    margin-bottom: 1em;
}

@media screen and (min-width: 1300px) {
    #connection-left {
        align-self: stretch;
    }
    #connection-right {
        background-color: var(--blanc-grise);
        background-image: url("../../../public/img/femme-hotline.jpg");
        background-position: right;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: contain;
        width: 100%;
        display: block;
    }
}
