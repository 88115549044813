.page-abonnements-admin h1 {
    background: var(--violet-clair);
    padding: 60px 15px;
    border-radius: 15px;
    max-width: var(--width-table);
    margin-inline: auto;
    margin-top: 0;
    box-sizing: border-box;
}

.page-abonnements h1 > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.empty-liste-abonnements-container {
    width: min(1200px,100%);
    box-sizing: border-box;
    margin: 0 auto;
    padding: 1.5em 1em;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 #0000002e;
    background: var(--blanc);
    text-align: center;
}

.empty-liste-abonnements-container a{
    color: var(--bleu-sur-clair-AA);
}

.empty-liste-abonnements-container p:last-of-type{
    margin-top: 60px;
    margin-bottom: 0;
}

.abonnements-wrapper-liens {
    width : var(--width-table);
    margin-inline: auto;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
}

a.lien-vue-all-abonnements {
    max-width: fit-content;
    background: var(--noir);
    color: var(--blanc);
    border-radius: 15px;
    padding: 5px 15px;
    text-decoration-line: none;
}

a.lien-vue-all-abonnements:hover {
    color: var(--bleu);
}

table.liste-abonnements caption.tri-abonnements {
    text-align: start;
    padding-inline: 1em;
}

table.liste-abonnements caption.tri-abonnements .bouton-tri:first-of-type {
    /* Espace entre le "Trier par" et le premier bouton de tri */
    margin-inline-start: 4px;
}

table.liste-abonnements td.statut-abonnement {
    text-align: center;
}

table.liste-abonnements td .texte-statut-abonnement {
    font-size: 0.70em;
    margin-top: 10px;
    font-weight: 600;
}

table.liste-abonnements td:has(.infos-abonnement) {
    font-family: "PoppinsSemiBold";
}

table.liste-abonnements td .infos-abonnement {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 15px;
    margin-top: 0.5em;
}

table.liste-abonnements td .code-abonnement {
    border: solid 1px var(--noir);
    border-radius: 6px;
    padding: 1.5px 5px;
}

table.liste-abonnements td .date-abonnement {
    font-family: "PoppinsRegular";
}

/* Lorsqu'il n'y a pas de date de fin, on affiche seulement un "-", centré dans la cellule */
table.liste-abonnements td .date-abonnement:empty {
    display: block;
    text-align: center;
}

table.liste-abonnements td .date-abonnement:empty::after {
    content: "—";
    color: var(--gris-sur-blanc-AA);
}

table.liste-abonnements td .prix-abonnnement {
    font-family: "PoppinsLightItalic";
}

table.liste-abonnements .lien-vue-abonnement a {
    background: var(--noir);
    color: var(--blanc);
    border-radius: 15px;
    padding: 5px 15px;
    text-decoration-line: none;
}

table.liste-abonnements .lien-vue-abonnement a:hover {
    color: var(--bleu);
}

@container contenu (width < 800px) {
    table.liste-abonnements {
        word-break: break-word;
    }

    table.liste-abonnements thead {
        display: none;
    }

    table.liste-abonnements tr {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(5, auto);
        gap: 5px 10px;
        padding: 1em;
        background: var(--blanc);
    }

    table.liste-abonnements td {
        padding: 0.5em 0.5em;
    }

    table.liste-abonnements .statut-abonnement {
        grid-row: -1 / 1;
    }

    table.liste-abonnements td .date-abonnement:empty {
        display: unset;
    }

    table.liste-abonnements td.lien-vue-abonnement {
        justify-self: right;
    }

    table.liste-abonnements .small-label {
        display: inline-block;
    }
}

@container contenu (width < 400px) {
    table.liste-abonnements tr {
        display: grid;
        grid-template-columns: 1fr;
    }

    table.liste-abonnements .statut-abonnement {
        grid-row: unset;
    }

    /* Sur petit écran, on met chaque bouton de tri sur sa propre ligne */
    table.liste-abonnements caption.tri-abonnements .bouton-tri {
        display: block;
    }

    /* Et on enlève les "|" de séparation */
    table.liste-abonnements caption.tri-abonnements .bouton-tri::after {
        content: "";
    }

    /* On enlève le margin horizontal du premier bouton, et on met un margin vertical pour
     * marquer la séparation avec "Trier par"
     */
    table.liste-abonnements caption.tri-abonnements .bouton-tri:first-of-type {
        margin-inline-start: unset !important;
        margin-block-start: 4px;
    }

}
