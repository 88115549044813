/**** FONTS ***/

@font-face {
    font-family: "PoppinsBlack";
    src: local("PoppinsBlack"), url("./fonts/Poppins-Black.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsBlackItalic";
    src: local("PoppinsBlackItalic"), url("./fonts/Poppins-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsBold";
    src: local("PoppinsBold"), url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsBoldItalic";
    src: local("PoppinsBoldItalic"), url("./fonts/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: local("PoppinsSemiBold"), url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsSemiBoldItalic";
    src: local("PoppinsSemiBoldItalic"), url("./fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsItalic";
    src: local("PoppinsItalic"), url("./fonts/Poppins-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsMedium";
    src: local("PoppinsMedium"), url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsMediumItalic";
    src: local("PoppinsMediumItalic"), url("./fonts/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsRegular";
    src: local("PoppinsRegular"), url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsLightItalic";
    src: local("PoppinsLightItalic"), url("./fonts/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "PoppinsLight";
    src: local("PoppinsLight"), url("./fonts/Poppins-Light.ttf") format("truetype");
}

/**** COLORS ***/

:root {
    --noir: #151828;
    --bleu-clair:#D9EEF6;
    --blanc-grise: #F4F4F5;
    --bleu: #108ACF;
    --blanc: #fcfdfe;
    --rouge-fonce: #710913;
    --rouge-clair: #ffb2ba;
    --rouge-vif: #db290a;
    /* Score AA pour texte sur fond --blanc */
    --vert-sur-blanc-AA: #00862e;
    /* Score AA pour texte sur fond --blanc */
    --gris-sur-blanc-AA: #676b7e;
     /* Score AA pour texte sur fond clair (--blanc, --bleu-clair) */
    --bleu-sur-clair-AA:#0D6EA6;
    /* Score AA pour texte sur fond --bleu-clair */
    --bleu-sur-bleu-clair-AA: #326675;
    --gris-clair:#e8e8e8;
    --jaune: #ffdc64;
    --jaune-clair:#ffeba8;
    --marron:#6c5607;
    --marron-clair:#846705;
    --violet-clair:#f7e6f1;
    --violet-fonce:#442e3e;
    --bleu-moyen:#7cade6;
    --bleu-fonce:#132d4a;
    --vert-clair: #6fd169;
    --vert-fonce: #1b4d1a;
}

/**** WIDTHS & HEIGHTS ***/

/** VARIABLES **/
:root {
    --header-height: 80px;
    --small-header-height: 50px;
}