.section-hotline {
    display: flex;
    gap:10px;
    align-items: center;
    padding: 1.5em 1.5em 0 1.5em;
    margin-left: auto;
    margin-right: 1.5em;
    max-width: fit-content;
    color: var(--noir);
    transition: 0.3s;
}

.section-hotline:is(:hover, :focus) {
    text-decoration: underline;
    color: var(--bleu);
    transform: translate(5px);
}

.section-hotline:is(:hover, :focus) .icone-hotline {
    color: var(--blanc);
    background-color: var(--bleu);
}

.section-hotline .icone-hotline {
    border-radius: 50px;
    padding: 10px;
    color: var(--blanc);
    background-color: var(--noir);
    aspect-ratio: 1 / 1;
}

.section-hotline > span {
    font-family: "PoppinsBold";
    font-size: 1.1em;
    text-align: center;
}

.page-home {
    max-width: 900px;
    margin-inline: auto;
}

.section-infocom-container {
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 1.5em;
    text-align: center;
    word-break: break-word;
    box-shadow: 0 5px 10px 0px #0000002e;
}

.section-infocom-container.section-infocom-alerte {
    color: var(--rouge-fonce);
    background-color: var(--rouge-clair);
}

.section-infocom-container.section-infocom-info {
    background-color: var(--bleu-clair);
}

.section-infocom-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 0;
    font-family: "PoppinsSemiBold";
    font-size: 1.5em;
}

.section-infocom-container h2 {
    font-family: "PoppinsMedium";
    font-size: 1.2em;
    color: currentColor;
    margin: unset;
}

.section-infocom-container a {
    color: currentColor;
    text-decoration: underline;
}

.section-infocom-container a:hover {
    color: var(--bleu-sur-clair-AA);
}

.section-derniers-contrats-container {
    display: flex;
    justify-content: stretch;
}

.empty-liste-container {
    text-align: center;
}

.empty-liste-container a{
    color: var(--bleu-sur-clair-AA);
}

.empty-liste-container p:last-of-type{
    margin-top: 60px;
    margin-bottom: 0;
}

.liste-derniers-contrats-container {
    background: var(--bleu-clair);
    border-radius: 15px 0 0 15px;
    padding: 2.5em;
    flex-grow: 1;
}

h1.sous-titre-accueil {
    margin-block: 0px 30px;
    font-size: 20px;
}

.liste-derniers-contrats-container .load-failed {
    margin-block: 0;
}

ul.liste-derniers-contrats {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

ul.liste-derniers-contrats li {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(5, auto);
    gap: 0.5em 1em;
    padding: 1.5em 1em;
    list-style: none;
    background: var(--blanc);
    box-shadow: 0 5px 10px 0px #0000002e;
    border-radius: 10px;
}

ul.liste-derniers-contrats li span.statut-abonnement {
    grid-row: -1 / 1;
    text-align: center;
}

ul.liste-derniers-contrats li div.texte-statut-abonnement {
    font-size: 0.70em;
    margin-top: 10px;
    font-weight: 600;
}

ul.liste-derniers-contrats li :is(.nom-abonnement, .code-abonnement) {
    font-family: "PoppinsSemiBold";
}

ul.liste-derniers-contrats li .infos-abonnement {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px 15px;
}

ul.liste-derniers-contrats li .code-abonnement {
    border: solid 1px var(--noir);
    border-radius: 6px;
    padding: 3px 5px;
}

ul.liste-derniers-contrats li div:not([class]) {
    display: flex;
    flex-wrap: wrap;
}

ul.liste-derniers-contrats li span.date-abonnement {
    font-family: "PoppinsRegular";
}

ul.liste-derniers-contrats li span.date-abonnement:empty::after {
    content: "—";
    color: var(--gris-sur-blanc-AA);
}

ul.liste-derniers-contrats li span.prix-abonnnement {
    font-family: "PoppinsLightItalic";
}

.liste-derniers-contrats-container a.lien-liste-abonnements {
    display: block;
    max-width: max-content;
    margin-inline: auto;
    margin-top: 40px;
    background: var(--noir);
    color: var(--blanc);
    border-radius: 20px;
    padding: 8px 25px;
    font-family: "PoppinsSemiBold";
    font-size: 0.8em;
}

.liste-derniers-contrats-container a.lien-liste-abonnements:hover {
    color: var(--bleu);
}

.section-derniers-contrats-container img {
    border-radius: 0 15px 15px 0;
    object-fit: cover;
}

@container contenu (width < 850px) {
    .liste-derniers-contrats-container {
        border-radius: 15px;
    }

    .section-derniers-contrats-container img {
        display: none;
    }
}

@container contenu (width < 450px) {
    .section-hotline {
        margin-inline: auto;
    }

    .liste-derniers-contrats-container {
        padding:1em;
    }

    ul.liste-derniers-contrats li {
        grid-template-columns: 1fr;
    }

    ul.liste-derniers-contrats li span.statut-abonnement {
        margin-bottom:10px;
    }
}

/****************
 SECTION ADMIN
 ****************/
.section-admin {
    display: flex;
    justify-content: center;
    height: 100%;

    & .section-infocom-container {
        text-align: left;
        font-size: 0.9em;
        line-height: 1.5;
        /* On ajuste la largeur par rapport aux autres sections en soustrayant la somme de leurs padding left et right
         afin de pouvoir les aligner verticalement*/
        max-width: calc(100% - 2*30px);
        margin-inline: auto;
        margin-block: 50px 60px;
        box-sizing: border-box;

        & > :is(h1, p) {
            margin: 0;
        }

        & > h1 {
            display: block;
            font-size: 1.2em;
            margin-bottom: 2px;

            svg {
                margin-inline-end: 10px;
            }
        }

        & > p {
            display: inline;

            & + p {
                padding-inline-start: 4px;
            }
        }
    }
}

.section-admin-container {
    flex: 1;
    max-width: 1100px;
}

.data-collectivite-container {
    padding: 30px;
    max-width: 100%;
    box-sizing: border-box;
    margin-inline: auto;
    margin-bottom: 30px;
    text-align: center;
    word-break: break-word;
}
.data-collectivite-container .label-autocomplete-collectivite {
    color: var(--bleu-sur-clair-AA);
}

.data-collectivite-container > div {
    width: min(400px, 100%);
    margin-inline: auto;
    margin-top: 10px;
}

.data-collectivite-container div.aa-Autocomplete form {
    background: var(--blanc);
    border-radius: var(--border-radius-input) ;
    border: none;
}

.data-collectivite-container div.aa-Autocomplete form:focus-within {
    /* override du style de l'autocomplete Algolia */
    box-shadow: var(--gris-sur-blanc-AA) 0 0 0 2px,
                inset var(--gris-sur-blanc-AA) 0 0 0 2px;
}

.data-collectivite-container div.aa-Autocomplete form label svg{
    color: var(--bleu-sur-clair-AA);
}

.aa-Panel section [data-autocomplete-source-id="collectivites"] ul li + li{
    margin-top: 10px;
}

.aa-Panel section [data-autocomplete-source-id="collectivites"] ul li div.siret-container  {
    font-size: 0.85em;
    font-family: "PoppinsLightItalic";
    margin-top: 3px;
}

section[data-autocomplete-source-id="collectivites"] ul li[aria-selected="true"]  {
    background: var(--bleu-clair);
}

.data-collectivite-container section div.chargement-donnees-container {
    margin-top: 60px;
}

.data-collectivite-grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    gap: 30px 30px;
    max-width: 100%;
    box-sizing: border-box;
    margin-inline: auto;
    background: var(--blanc);
    padding: 1.5em 0.5em;
    border-radius: 15px;
    box-shadow: 0 5px 10px 0px #0000002e;
    box-sizing: border-box;
    margin-top: 20px;
    scroll-margin-top: 20px;
}

.data-collectivite-grid-container .infos-generales-container {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: subgrid;
}

@supports not (grid-template-columns: subgrid) {
    .data-collectivite-grid-container .infos-generales-container {
        grid-template-columns: inherit;
        gap: 0 30px;
    }
}

.data-collectivite-grid-container .infos-generales-container .close-button {
    grid-column: 1/-1;
    grid-row: 1/2;
    max-width: min-content;
    padding: 0 0.5em 0.5em 0.5em;
    margin-left: auto;
    color: var(--bleu-sur-clair-AA)
}

.data-collectivite-container div.infos-generales {
    display: flex;
    flex-direction: column;
    align-content: baseline;
    align-items:end;
    text-align: end;
    background: unset;
    box-shadow: unset;
    padding: 0.5em;
}

.data-collectivite-container div.identite {
    align-items: center;
}

.data-collectivite-container div.identite span.nom {
    font-family: "PoppinsSemiBold";
    font-size: 1.3em;
    color: var(--bleu-sur-clair-AA);
    text-wrap: balance;
    text-align: center;
}

.data-collectivite-container div.identite span.statut-collectivite-non-adherent,
.data-collectivite-container div.identite span.statut-collectivite-adherent {
    font-family: "PoppinsSemiBold";
    color: var(--blanc);
    border-radius: 5px;
    max-width: fit-content;
    padding: 2px 10px;
    margin-inline: auto;
    margin-block: 10px;
}
.data-collectivite-container div.identite span.statut-collectivite-adherent {
    background: var(--vert-sur-blanc-AA);
}

.data-collectivite-container div.identite span.statut-collectivite-non-adherent {
    background: var(--rouge-vif);
}

.data-collectivite-container div.infos-generales span > .icone-adresse {
    color: var(--bleu-sur-clair-AA);
    margin-right: 10px;
}

.data-collectivite-container div.infos-generales span.ref-numerian {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}

.data-collectivite-container div.infos-generales span.ref-numerian img{
    margin-right: 5px;
}

.data-collectivite-container div.infos-generales span.label {
    font-family: "PoppinsLightItalic";
}

.data-collectivite-grid-container div[class*="data-bloc-"]{
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 100px;
    padding: 1em 0.5em;
    border-radius: 15px;
    box-shadow: 0 5px 10px 0px #0000002e;
    text-align: left;
    background-color: var(--data-bloc-background-color);
    color: var(--data-bloc-color);
}

.data-bloc-jaune {
    --data-bloc-background-color:var(--jaune);
    --data-bloc-color: var(--marron);
}

.data-bloc-violet {
    --data-bloc-background-color: var(--violet-clair);
    --data-bloc-color: var(--violet-fonce);
}

.data-bloc-bleu {
    --data-bloc-background-color: var(--bleu-moyen);
    --data-bloc-color: var(--bleu-fonce);
}

.data-bloc-rouge {
    --data-bloc-background-color: var(--rouge-clair);
    --data-bloc-color: var(--rouge-fonce);
}

.data-collectivite-grid-container div[class*="data-bloc-"] details {
    margin-bottom: 15px;
}

.data-collectivite-grid-container div[class*="data-bloc-"] summary {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap-reverse;
    align-items: center;

    & > div {
        display: list-item;
        list-style: disclosure-closed inside;
        font-size: 1.1em;

        details[open] & {
            list-style: disclosure-open inside;
        }

        & > span {
            font-family: "PoppinsSemiBold";
        }
    }
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    border-left: 1px solid transparent;
    padding: 0.5em;
    /* on ajoute un margin afin d'aligner la bordure gauche avec la fleche de details*/
    margin-left: 3.25px;

    @media (width < 320px)  {
        grid-template-columns:auto;
        gap: unset;
    }
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container:has([data-ticket-statut]) {
    border-color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container:has([data-abonnement-statut]) {
    border-color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container:has([data-compte-statut]) {
    border-color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container :is([data-ticket-statut],[data-abonnement-statut],[data-compte-statut]) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (width < 320px)  {
        justify-content: unset;
    }
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container :is([data-ticket-statut],[data-abonnement-statut],[data-compte-statut])::before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color:currentColor
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container span[class*="nombre-"] {
    font-family: "PoppinsSemiBold";
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container .nombre-ticket {
    color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container .nombre-abonnement {
    color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container .nombre-compte {
    color: var(--data-bloc-color);
}

.data-collectivite-grid-container div[class*="data-bloc-"] div.icone-noResults {
    display: flex;
    min-height: 100%;
    /* On ajoute un margin-top négatif égal à la somme des padding top et bottom de
       la div parente afin de pouvoir centrer l'icone verticalement par rapport à cette dernière*/
    margin-top: calc(2*(-1em));
    justify-content: center;
    align-items: center;
}

.data-collectivite-grid-container span.titre-data-bloc {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    align-items: center;
    font-size: 1.1em;
}

.data-collectivite-grid-container span.alerte-compte {
    background: var(--noir);
    color: var(--blanc);
    padding: 2px 3px;
    font-size: 0.7em;
    border-radius: 5px;
}

.data-collectivite-grid-container ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.data-collectivite-grid-container ul li {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, auto);
    gap: 0.5em 1em;
    padding: 1em;
    list-style: none;
    background: var(--blanc);
    box-shadow: 0 5px 10px 0px #0000002e;
    border-radius: 10px;
}

.data-collectivite-grid-container ul li:last-of-type {
    margin-bottom: 10px;
}

.data-collectivite-grid-container ul li :is(div.identification-ticket, div.identification-compte) {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
}

[data-abonnement-statut="En cours"],
[data-ticket-statut^="En cours"],
[data-ticket-statut="Résolu"],
[data-compte-statut="Activé"] {
    color: var(--vert-sur-blanc-AA);
}

[data-abonnement-statut="Terminé"],
[data-ticket-statut="Clos"] {
    color: var(--noir);
}

[data-ticket-statut="Nouveau"] {
    color: var(--bleu-sur-clair-AA);
}

[data-ticket-statut="En attente"] {
    color: var(--gris-sur-blanc-AA);
}

[data-compte-statut="Non activé"] {
    color: var(--rouge-vif);
}

.data-collectivite-grid-container  ul li :is([data-abonnement-statut], [data-ticket-statut], [data-compte-statut])::before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
}

.data-collectivite-grid-container ul li :is(span.id-ticket, span.id-compte) {
    font-family: "PoppinsSemiBold";
    margin-left: auto;
}

.data-collectivite-grid-container ul li :is(div.titre-ticket, div.nom-compte) {
    font-family: "PoppinsSemiBold";
}

.data-collectivite-grid-container ul li div.container-with-details-button {
    display: flex;
    justify-content: space-between;
    gap: 10px 25px;
    flex-wrap: wrap;
}

.data-collectivite-grid-container ul li div.date-ticket {
    display: flex;
    flex-direction: column;
}

.data-collectivite-grid-container ul li div.container-with-details-button .btn-details {
    background: var(--noir);
    border-radius: 15px;
    color: var(--blanc);
    font-family: PoppinsRegular;
    text-decoration-line: none;
    padding: 2px 10px;
    margin-top: auto;
    margin-left: auto;
}

.data-collectivite-grid-container ul li :is(
    span.id-ticket, div.date-ticket, div.entite-ticket, div.date-abonnement-container,
    span.id-compte, div.email-compte
) {
    color: var(--noir)
}

.data-collectivite-grid-container ul li div:has(.code-abonnement) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
    justify-content: space-between;
    align-items: center;
}

.data-collectivite-grid-container ul li :is(.code-abonnement, .infos-abonnement) {
    font-family: "PoppinsSemiBold";
}

.data-collectivite-grid-container ul li div.infos-abonnement {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
    align-items: center;
}

.data-collectivite-grid-container ul li .code-abonnement {
    border: solid 1px var(--noir);
    border-radius: 6px;
    padding: 1.5px 5px;
    color: var(--noir);
}

.data-collectivite-grid-container ul li div.date-abonnement-container {
    display: flex;
    flex-direction: column;
}

.data-collectivite-grid-container ul li span.date-abonnement:empty::after {
    content: "—";
}

.data-collectivite-grid-container div[class*="data-bloc-"] .btn-view-all {
    max-width: fit-content;
    background: var(--noir);
    color: var(--blanc);
    border-radius: 15px;
    padding: 2px 10px;
    margin-left: auto;
    text-decoration-line: none;
}

.data-collectivite-grid-container div[class*="data-bloc-"] :is(.btn-view-all, .btn-details):hover {
    color: var(--bleu);
}

.stats-grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    padding:30px;
    gap: 30px 30px;
    max-width: 100%;
    box-sizing: border-box;
    margin-inline: auto;
    margin-bottom: 60px;
}

[class*="stat-bloc-"] {
    background-color: var(--stat-bloc-background-color);
    color: var(--stat-bloc-color);
    border: 4px solid transparent;
}

.stat-bloc-bleu {
    --stat-bloc-background-color: var(--bleu-moyen);
    --stat-bloc-color: var(--bleu-fonce);
}

.stat-bloc-bleu.selected {
    border-color: var(--bleu-fonce);
}

.stat-bloc-vert {
    --stat-bloc-background-color: var(--vert-clair);
    --stat-bloc-color: var(--vert-fonce);
}

.stat-bloc-vert.selected {
    border-color: var(--vert-fonce);
}

.stat-bloc-rouge {
    --stat-bloc-background-color: var(--rouge-clair);
    --stat-bloc-color: var(--rouge-fonce);
}

.stat-bloc-rouge.selected {
    border-color:  var(--rouge-fonce);
}

.stat-bloc-jaune {
    --stat-bloc-background-color: var(--jaune);
    --stat-bloc-color: var(--marron);
}

.stat-bloc-jaune.selected {
    border-color: var(--marron);
}

.stat-bloc-jaune-clair {
    --stat-bloc-background-color: var(--jaune-clair);
    --stat-bloc-color: var(--marron-clair);
}

.stat-bloc-jaune-clair.selected {
    border-color: var(--marron-clair);
}

.stat-bloc-bleu-clair {
    --stat-bloc-background-color: var(--bleu-clair);
    --stat-bloc-color: var(--bleu-sur-bleu-clair-AA);
}

.stat-bloc-bleu-clair.selected {
    border-color: var(--bleu-sur-bleu-clair-AA);
}

.stats-grid-container button {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    min-height: 160px;
    border-radius: 15px;
    box-shadow: 0 5px 10px 0px #0000002e;
    text-align: left;
}

.chiffre-stats {
    font-size: 2.2em;
    font-family: "PoppinsBold";
}

 @container contenu (width < 450px) {
    .stats-grid-container,
    .data-collectivite-grid-container {
        grid-template-columns: 1fr;
    }
}

@container contenu (width < 700px) {
    /*
     * En dessous d'une certaine taille, on cache la liste des différentes stats lorsque le volet est
     *  visible pour qu'il prenne toute la place.
     */
    .section-admin.volet-detail-stats-visible .section-admin-container {
        display: none;
    }

    .section-admin.volet-detail-stats-visible .section-details-stats {
        /* On ajuste la taille pour que le volet ne colle pas à la barre latérale */
        width: 90%;
    }
}

@media (width < 700px) {
    /* Lorsque la barre latérale est cachée, on affiche le volet sur toute la largeur de l'écran */
    .section-admin.volet-detail-stats-visible .section-details-stats {
        width: 100%;
    }
}

@media (width < 320px)  {
    .data-collectivite-grid-container div[class*="data-bloc-"] .repartition-container :is([data-ticket-statut], [data-abonnement-statut],[data-compte-statut]) + span:not(:last-of-type) {
        margin-bottom:10px;
    }
}
