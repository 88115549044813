form.formulaire-contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: var(--bleu-clair);
    border-radius: 15px;
    padding: 2.5em;
}

form.formulaire-contact > :is(.success-msg, .error-msg) {
    margin: 0 auto 1em;
}

form.formulaire-contact > section {
    display: grid;
    gap: 15px;
    background: var(--blanc);
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px #0000002e;
}

form.formulaire-contact label {
    font-family: "PoppinsSemiBold";
}

form.formulaire-contact :is(input, textarea, select) {
    background-color: transparent;
    border: 2px solid var(--bleu-clair);
    border-radius: var(--border-radius-input);
    padding: 0.5em;
    min-width: 0;
}

form.formulaire-contact :is(input, textarea, select):focus {
    border-color: var(--gris-sur-blanc-AA);
}

form.formulaire-contact button {
    width: min(150px, 100%);
    margin-inline: auto;
    font-family: "PoppinsSemiBold";
}

section.contact-tel {
    margin-top: 40px;
    text-align: center;
}

@container contenu (width < 450px) {
    form.formulaire-contact {
        padding: 1em;
    }

    form.formulaire-contact > section {
        padding: 0.5em;
    }
}