section.page-comptes-admin {
    --section-max-width: 650px;
}

.page-comptes-admin h1 {
    max-width: var(--section-max-width);
    box-sizing: border-box;
    padding: 60px 16px;
    margin-inline: auto;
    margin-block: 0 30px;
    background: var(--bleu-moyen);
    border-radius: 15px;
}

.page-comptes-admin h1 > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

ul.liste-comptes {
    padding-left: 0;
    margin-top: 0;
    margin-inline: auto;
    max-width: var(--section-max-width);
}

ul.liste-comptes li {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 20px;
    justify-content: end;
    background: var(--blanc);
    box-shadow: 0 5px 10px 0 #0000002e;
    border-radius: 10px;
    list-style: none;
    padding: 1.5em 1em;
    word-break: break-word;
}

ul.liste-comptes li + li {
    margin-top: 30px;
}

ul.liste-comptes li div.identification-compte {
    flex: 1 1;
    min-width: min(200px, 100%);
}

ul.liste-comptes li [data-compte-statut] {
    margin-bottom: 0.5em;

    &[data-compte-statut="Activé"] {
        color: var(--vert-sur-blanc-AA);
    }

    &[data-compte-statut="Non activé"] {
        color: var(--rouge-vif);
    }
}

ul.liste-comptes li [data-compte-statut]::before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
}

ul li  :is(div.nom-compte, .id-compte) {
    font-family: "PoppinsSemiBold";
}

ul.liste-comptes li .id-compte {
    margin-left: auto;
    margin-bottom: 0.5em;
}