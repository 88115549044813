section.page-tickets {
    --section-max-width: 650px;
}

section.page-tickets .page-top-section-header {
    max-width: var(--section-max-width);
    margin-inline: auto;
    border-radius: 15px;
}

section.page-tickets-admin .page-top-section-header {
    background-color: var(--jaune);
}

section.page-tickets-admin h1.titre-page {
    padding: 24px 16px 16px 16px;
    margin-block: 0;
}

.page-tickets h1 > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-tickets a.lien-creation-ticket {
    display: flex;
    max-width: fit-content;
    margin-inline: auto;
    margin-bottom: 40px;
    background: var(--vert-sur-blanc-AA);
    color: var(--blanc);
    padding: 1em;
    border-radius: 15px;
    font-family: "PoppinsSemiBold";
    text-align: center;
}

form.filtre-liste-tickets {
    padding: 1.6em 1em 1.5em 1em;
    margin-bottom: 30px;
    background: var(--bleu-clair);
    border-radius: 15px ;
}

section.page-tickets-admin form.filtre-liste-tickets {
    background: transparent;
}

form.filtre-liste-tickets label.form-search-input-label {
    display: flex;
    margin-inline: auto;
    align-items: center;
    justify-content: center;
    width: min(300px, 100%);
    max-width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: var(--border-radius-input);
    background: var(--blanc);
}

form.filtre-liste-tickets label .icone-recherche {
    color: var(--bleu);
}

section.page-tickets-admin form.filtre-liste-tickets label .icone-recherche {
    color: var(--marron)
}

form.filtre-liste-tickets input[name="texte-filter"] {
    width: 100%;
    border: none;
    background-color: transparent;
    margin-left: 5px;
}

form.filtre-liste-tickets section.checkbox-filter {
    display: grid;
    gap: 10px 25px;
    justify-content: center;
    margin-top: 1.5em;
    grid-template-columns: repeat(auto-fill, min(100%,120px));
}

form.filtre-liste-tickets section.checkbox-filter .form-check-label {
    word-break: break-all;
}

form.filtre-liste-tickets section.checkbox-filter .form-check-input {
    margin-right: 5px;
    margin-left: 0;
    margin-block: 0;
}

ul.liste-tickets {
    padding-left: 0;
    margin-top: 0;
    max-width: var(--section-max-width);
    margin-inline: auto;
}

ul.liste-tickets > li {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 15px 20px;
    padding: 1.5em 1em;
    background: var(--blanc);
    box-shadow: 0 5px 10px 0px #0000002e;
    border-radius: 10px;
    word-break: break-word;
}

ul.liste-tickets li + li {
    margin-top: 30px;
}

ul.liste-tickets > p.empty {
    text-align: center;
}

ul.liste-tickets li section.identification-ticket {
    flex: 1;
    /* pas plus petit que 200px, ou que 100% du parent, pour éviter de dépasser sur petit écran */
    min-width: min(200px, 100%);
}

[data-statut-ticket="Nouveau"] {
    --couleur-statut-ticket: var(--bleu-sur-clair-AA);
}

[data-statut-ticket^="En cours"] {
    --couleur-statut-ticket: var(--vert-sur-blanc-AA);
}

[data-statut-ticket="En attente"] {
    --couleur-statut-ticket: var(--gris-sur-blanc-AA);
}

[data-statut-ticket="Résolu"] {
    --couleur-statut-ticket: var(--vert-sur-blanc-AA);
}

[data-statut-ticket="Clos"] {
    --couleur-statut-ticket: var(--noir);
}

[data-statut-ticket] {
    color: var(--couleur-statut-ticket, var(--noir));
}

[data-statut-ticket]::before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
}

ul.liste-tickets li .titre-ticket {
    font-family: "PoppinsSemiBold";
    margin-top: 0.5em;
}

ul.liste-tickets li :is(.entite-ticket, .date-ticket) {
    font-size: 0.9em;
}

ul.liste-tickets li section.etat-ticket {
    text-align: end;
}

ul.liste-tickets li .id-ticket {
    font-family: "PoppinsSemiBold";
    margin-bottom: 0.5em;
}

ul.liste-tickets li .date-ticket {
    font-family: "PoppinsLightItalic";
}

ul.liste-tickets li .bouton-details-ticket {
    background: var(--noir);
    color: var(--blanc);
    border-radius: 15px;
    display: inline-block;
    padding: 5px 15px;
    margin-top: 1em;
    text-decoration-line: none;
    font-family: "PoppinsRegular";
}

ul.liste-tickets li .bouton-details-ticket:hover {
    color: var(--bleu);
}