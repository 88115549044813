form.formulaire-ticket {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: var(--bleu-clair);
    border-radius: 15px;
    padding: 2.5em;
}

form.formulaire-ticket fieldset {
    display: grid;
    gap: 15px;
    background: var(--blanc);
    padding: 1.5em;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0px #0000002e;
    border: none;
}

form.formulaire-ticket fieldset legend {
    background: var(--blanc);;
    padding: 5px 20px;
    font-size: 1.2em;
    color: var(--gris-sur-blanc-AA);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -5px 10px -7px #0000002e;
}

form.formulaire-ticket fieldset div + div {
    margin-top: 15px;
}

form.formulaire-ticket fieldset div span {
    font-family: "PoppinsSemiBold";
}

form.formulaire-ticket div.question-structure-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

form.formulaire-ticket label:not(.label-light) {
    font-family: "PoppinsSemiBold";
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    line-height: 1;
}

form.formulaire-ticket .description-question p {
    margin: 0;
    font-size: 0.8em;
    font-family: "PoppinsLightItalic";
}

form.formulaire-ticket :is(select, input, textarea) {
    border: 2px solid var(--bleu-clair);
    border-radius: var(--border-radius-input);
    padding: 0.5em;
    min-width: 0;
    width: 100%;
    box-sizing: border-box;
}

form.formulaire-ticket select {
   max-width: 300px !important;
   background: var(--blanc);
}

form.formulaire-ticket label.label-light input {
    width: unset;
    margin-right: 10px;
}

form.formulaire-ticket section.boutons-action {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-inline: auto;
    justify-content: center;
    background: unset;
    box-shadow: unset;
    padding: 0;
}

@container contenu (width < 450px) {
    form.formulaire-ticket {
      padding: 1em;
    }
}

@container contenu (width < 300px) {
    form.formulaire-ticket fieldset {
        padding: 1em;
    }

    form.formulaire-ticket fieldset legend {
        padding: 5px 10px;
    }
}