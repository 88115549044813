button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    transition: 0.3s;
}

.submit-btn {
    background-color: var(--bleu);
    color: var(--noir);
    font-weight: 600;
    padding: 15px 20px;
    border-radius: var(--btn-border-radius);
}

.submit-btn[disabled] {
    opacity: 0.7;
    cursor: unset;
}

.submit-btn:not([disabled]):is(:hover,:focus) {
    background-color: var(--noir);
    color: var(--bleu);
}

.secondary-btn {
    background-color: var(--noir);
    color: var(--blanc);
    font-weight: 600;
    padding: 15px 20px;
    border-radius: var(--btn-border-radius);
    text-decoration: none;
}

.secondary-btn:hover {
    color: var(--bleu);
}

.bouton-retour {
    background: var(--noir);
    color: var(--blanc);
    border-radius: 20px;
    padding: 10px 15px;
    text-decoration-line: none;
    font-size: 0.80rem;
    font-family: "PoppinsSemiBold";
}

.bouton-retour:hover {
    background: var(--noir);
    color: var(--bleu);
}

.bouton-tri.actif {
    text-decoration: underline;
}

.bouton-tri.actif svg {
    margin-inline-start: 0.2em;
}

.bouton-tri::after {
    content: "|";
    padding-inline: 4px;
}

.bouton-tri:last-of-type::after {
    content: "";
}


/**********
VARIABLES
***********/

:root {
    --btn-border-radius : 20px;
}