section.page-erreur {
    text-align: center;
    word-break: break-word;
}

section.page-erreur .icone-erreur {
    color: var(--bleu);
    margin-top: 20px;
    width: 50px;
    height: 50px;
}

section.page-erreur h1 {
    font-family: "PoppinsRegular";
    margin-block: 30px;
}

section.page-erreur .message-erreur-container {
    border: 1px solid var(--noir);
    border-radius: 5px;
    padding: 0.5em 0.5em;
    max-width: max-content;
    margin-inline: auto;
    margin-bottom: 80px;
    font-family: "PoppinsItalic";
}

section.page-erreur .message-erreur-container[open] {
    padding: 0.5em;
}

section.page-erreur .message-erreur-container summary {
    margin: -0.5em -0.5em 0;
    padding: 0.5em 0.5em 0;
    font-family: "PoppinsRegular";
}

section.page-erreur .message-erreur-container[open] summary {
    border-bottom: 1px solid var(--gris-sur-blanc-AA);
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
}
