.error-msg-mon-compte {
    width: fit-content;
}

.section-form-user-container {
    display: flex;
    justify-content: center;
    max-width: 1100px;
}

.formulaire-user {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 430px;
    min-width: 0;
    background-color: var(--bleu-clair);
    border-radius: 15px;
    padding: 1.5em;
    box-sizing: border-box;
}

.formulaire-user .item-container {
    display: flex;
    gap: 20px;
    padding: 1.5rem;
    border-radius: 15px;
    background-color: var(--blanc-grise);
    box-shadow: 0 5px 10px 0px #0000002e;
}

.formulaire-user .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 0;
    width: 350px;
    max-width: 100%;
}

.formulaire-user .input-container label {
    font-family: "PoppinsSemiBold";
    word-break: break-word;
}

.formulaire-user .input-container .error-msg {
    margin: initial;
    word-break: break-word;
}

.formulaire-user .display-only-input {
    color : var(--gris-sur-blanc-AA);
}

.formulaire-user .input-container-password {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 0;
}

.formulaire-user .input-container-password p.instructions-mdp {
    font-size: 0.9rem;
}

.formulaire-user .input-pwd-with-icon-container {
    display: flex;
    align-items: center;
    min-width: 0;
}

.formulaire-user .input-pwd-with-icon-container input {
    border-radius: 15px 0 0 15px;
    width: 350px;
    max-width: 100%;
    min-width: 0;
}

.formulaire-user .input-pwd-with-icon-container .icon-password-hidden {
    height: 38px;
    border-radius: 0 15px 15px 0;
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--bleu-clair);
    border: 1px solid var(--bleu-clair);
}

.formulaire-user .bouton-action {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-inline: auto;
    justify-content: center;
}

.section-form-user-container .mon-compte-img {
    display: none;
}

@media screen and (max-width: 350px) {
    .formulaire-user {
        padding: 0.7em;
    }

    .formulaire-user .item-container {
        flex-direction: column;
    }
}

@media screen and (min-width: 1200px) {

    .section-form-user-container {
        margin-inline: auto;
        justify-content: unset;
    }

    .formulaire-user {
        gap: 30px;
        padding: 2.5em;
        max-width: 100%;
        min-width: 500px;
    }

    .section-form-user-container .mon-compte-img {
        display: flex;
        margin-block: auto;
        width: 100%;
    }
}
